<template>
  <span class="nowrap">
    <announcement-status
      v-if="documentType === 'allocation-announcements'"
      :status="value"
      smallChip
      outlinedChip
    />
    <dispute-collaboration-status
      v-if="documentType === 'disputes' || documentType == 'collaboration-tasks'"
      :status="value"
      smallChip
      outlinedChip
    />
    <dunning-reminder-status
      v-if="documentType === 'dunning-reminders' || documentType === 'calendar-based-reminders'"
      :status="value"
      :document-type="documentType"
      smallChip
      outlinedChip
    />
    <promise-status
      v-if="documentType === 'promises'"
      :status="value"
      smallChip
      outlinedChip
    />
    <run-status
      v-if="documentType === 'runs'"
      :run="value"
      smallChip
      outlinedChip
    />
    <!-- <portal-status
      v-if="documentType === 'invoices'"
      :status="value"
      smallChip
      outlinedChip
    /> -->
    <invoice-status
      v-if="documentType === 'invoices'"
      :invoice="value"
    />
  </span>
</template>

<script>
// Required import as prop validators are not in the scope
import Column from '@/pages/search/controllers/column'
export default {
  inheritAttrs: false,
  components: {
    AnnouncementStatus: () => import('@/components/announcement-status'),
    DisputeCollaborationStatus: () => import('@/components/dispute-collaboration-status'),
    DunningReminderStatus: () => import('@/components/dunning-reminder-status'),
    PromiseStatus: () => import('@/components/promise-status'),
    InvoiceStatus: () => import('@/components/invoice-status'),
    RunStatus: () => import('@/components/run-status')
    // PortalStatus: () => import('@/components/portal-status')
  },
  computed: {
    documentType () {
      return this.column?.options?.documentType
    }
  },
  props: {
    value: {
      type: Object
    },
    column: Column
  }
}
</script>
